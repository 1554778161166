.testimonial__items{
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
    justify-content: center;
}
.testimonial__item{
    background-color: rgba(0, 0, 0, 0.3);
    max-width: 30rem;
    width: 30rem;
    border-radius: 8px;
    padding: 1rem;
    margin-top: 5rem;
}
.testimonial__item h2{
    margin-top: -5rem;
}
.testimonial__item p{
    font-weight: 200;
    margin-bottom: 2rem;
}
.testimonial__item small{
    display: block;
    margin-bottom: 2rem;
}

.testimonial__item img{
    width: 10rem;
    height: 10rem;
    object-fit: cover;
    border-radius: 50%;
    position: relative;
    transform: translateY(-50%);
}