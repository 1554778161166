
nav{
    background: rgba(0, 0, 0, 0.3);
    width: max-content;
    padding: 0.7rem 1.7rem;
    display: block;
    z-index: 2;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    bottom: 2rem;
    display: flex;
    gap: 2rem;
    border-radius: 3rem;
    backdrop-filter: blur(15px);
    -webkit-backdrop-filter: blur(15px);
}
nav a{
    background: transparent;
    padding: 0.9rem;
    border-radius: 50%;
    display: flex;
    color: var(--color-light);
    font-size: 1.1rem;
    transition: var(--transition);
}
nav img{
    height: 1.1rem;
}
nav a:hover{
    background-color: var(--color-primary);
    color: var(--color-secondry);
    transform: rotate(360deg);
}

nav a.active{
    background-color: var(--color-primary);
    color: var(--color-secondry);
}
/* ==================MEDIA QURIES (MEDIUM DEVICES)=============== */

@media screen and (max-width:1024px){
    
}

/* ==================MEDIA QURIES (SMALL DEVICES)=============== */

@media screen and (max-width:600px) {
    nav {
        gap: 0.3rem;
    }
    nav a:nth-child(4){
        display: none;
    }
}