.experience__container > h3{
    margin-top: 2rem;
    font-weight: 500;
    letter-spacing: 2px;
    font-size: 2rem;
    display: flex;
    justify-content: center;
    gap: 0.5rem;
}
.experience__container h3 img{
    width: 4rem;
    position: relative;
    bottom: 0rem;
    animation: rotateDoodle 1s infinite;
    transition: var(--transition);
}
@keyframes rotateDoodle {
    0%{
        transform: rotate(0deg);
    }50%{
        transform: rotate(20deg);
    }100%{
        transform: rotate(0deg);
    }
}
.exp__items__container{
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin-top: 3rem;
}

.exp__item{
    background-color: var(--color-secondry);
    color: var(--color-primary);
    padding: 2rem;
    border-radius: 8px;
    font-size: 2rem;
    position: relative;
    transition: var(--transition);
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}
.exp__item:hover{
    -webkit-box-shadow: 3px 3px 10px 3px #fde74c;
    -moz-box-shadow: 3px 3px 10px 3px #fde74c;
    box-shadow: 3px 3px 10px 3px #fde74c;
    bottom: 1rem;
}
.exp__item h3{
    font-size: 1rem;
}
.exp__item p{
    font-size: 1rem;
    max-width: 15rem;
    color: var(--color-light);
    font-weight: 300;
}