
.contact__data__container{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    position: relative;
    align-items: center;
    margin-top: 4rem;
}

.contact__container img{
    animation: rotatePerson 2s infinite;   
}
@keyframes rotatePerson {
    0%{
        transform: rotate(0deg);
    }20%{
        transform: rotate(10deg);
    }80%{
        transform: rotate(-10deg);
    }100%{
        transform: rotate(0deg);
    }
}
.contact__container .doodle__heart{
    width: 5rem;
    position: absolute;
    animation: animateHeart 1s infinite;
    top: 0;
}

@keyframes animateHeart {
    0%{
        width: 5rem;
    }50%{
        width: 7rem;
    }100%{
        width: 5rem;
    }
}

.contact__form__container{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 25rem;
}
.contact__form__container h3{
    color: var(--color-secondry);
    font-size: 1.5rem;
}
.contact__container input{
    width: 100%;
    padding: 0.9rem 1rem;
    margin-top: 1rem;
    border: none;
    border-radius: 8px;
}
.contact__container input:focus{
    outline-color: var(--color-secondry);
}
.contact__container input[type="submit"]{
    font-weight: 500;
}

/* ==================MEDIA QURIES (MEDIUM DEVICES)=============== */

@media screen and (max-width:1024px){
    
}

/* ==================MEDIA QURIES (SMALL DEVICES)=============== */

@media screen and (max-width:600px) {
    .contact__form__container{
        min-width: 18rem;
    }
    .contact__container img{
        width: 8rem;
    }
    .contact__container .doodle__heart{
        width: 2rem;
    }
    @keyframes animateHeart {
        0%{
            width: 2rem;
        }50%{
            width: 4rem;
        }100%{
            width: 2rem;
        }
    }
    
}
