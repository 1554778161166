.portfolio__items {
    display: flex;
    flex-wrap: wrap;
    gap: 2rem;
    justify-content: center;
    margin-top: 2rem;
}

.portfolio__item {
    background-color: rgba(0, 0, 0, 0.3);
    backdrop-filter: blur(15px);
    border-radius: 8px;
    max-width: 25rem;
    padding: 8px;
    position: relative;
    bottom: 0;
    transition: var(--transition);
    display: flex;
    flex-direction: column;

}

.portfolio__item:hover {
    bottom: 2rem;
    -webkit-box-shadow: 3px 3px 20px 3px #fde74c;
    -moz-box-shadow: 3px 3px 20px 3px #fde74c;
    box-shadow: 3px 3px 20px 3px #fde74c;
}

.portfolio__item img {
    width: 100%;
    border-radius: 8px;
    height: 18rem;
    object-fit: cover;
}

.portfolio__item h3 {
    margin-top: 1rem;
    color: var(--color-secondry);
}

.portfolio__item p {
    font-weight: 200;
    margin-top: 1rem;
}

.btn__container {
    margin-bottom: 2rem;
}

.btn__container a {
    text-decoration: none;
}

.check__btn {
    animation: backgroundColor 2s infinite;
    transition: 1s ease all;
    background: linear-gradient(90deg, transparent 0%, transparent 100%);
}

.check__btn:hover {
    background: linear-gradient(90deg, white 100%, transparent 0%);
    color: gray;
}
