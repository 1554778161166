
footer{
    background-color: var(--color-secondry);
    padding: 3rem 3rem 10rem;
}
footer h2{
    text-align: center;
    margin-bottom: 1rem;
    color: var(--color-primary);
}
.social__container__footer{
    font-size: 2rem;
    display: flex;
    gap: 2rem;
    color: var(--color-primary);
    justify-content: center;
}
.social__container__footer a{
    color: var(--color-primary);
}
.social__container__footer::after{
    content: '';
    position: absolute;
    width: 0.3rem;
    height: 10rem;
    background-color: var(--color-primary);
    transform: rotate(90deg);
}